@import './App.scss';

.group {
  position: relative;
  margin: 12px;
}

div.selection-list {
  span.label {
    font-size: 12px;
    color: $muted-color;
  }
}

ul.selection-list {
  list-style: none;
  padding: 0;
  border: 1px solid $dk-color;
  border-radius: 6px;

  li.item {
    cursor: pointer;
    background-color: $pm-text-color;
    color: $dk-color;
    border-bottom: 1px solid $dk-color;
    padding: 6px;
    font-size: 14px;
    color: $dk-color;
    transition: all $trans-time ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-bottom: 0px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:hover {
      transform: scale(1.02);
    }

    span {
      font-weight: bold;
    }
  }
}
