// VARS
@import './App.scss';

.group {
  position: relative;
  margin: 12px;
}

// INPUT TEXT

input {
  background: none;
  color: $muted-color;
  font-size: 18px;
  padding: 10px 0px;
  display: block;
  width: $width;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $muted-color;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    top: -14px;
    font-size: 12px;
    color: $pm-color;
  }
  &:focus ~ .bar:before {
    width: $width;
  }
}

// input[value=''] {
// }

input[value]:not([value='']) {
  &:valid ~ label {
    top: -14px;
    font-size: 12px;
    color: $muted-color;
  }
  &:focus ~ label {
    color: $pm-color;
  }
}

input[type='password'] {
  letter-spacing: 0.3em;
}

label {
  color: $muted-color;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 16px;
  transition: $trans-time ease all;
}

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $pm-color;
    transition: $trans-time ease all;
    left: 0%;
  }
}

// BUTTON

button {
  font-size: 18px;
  padding: 10px 0px;
  width: $width;
  outline: none;
  border: 0;
  border-radius: 6px;
  background: $pm-color;
  color: $pm-text-color;
  cursor: pointer;
  transition: all $trans-time ease;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    transform: scale(0.98);
  }

  @keyframes laoder-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader {
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: $pm-text-color;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: laoder-spin 1s linear infinite;
  }
}

// RIPPLE EFFECT

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform $trans-time, opacity 0.5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}
