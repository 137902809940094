// Default
$bg-color: #DAD8CA;
$pm-color: #318ed5;
$dk-color: #1d2127;
$pm-text-color: #ffffff;
$muted-color: mix(white, $bg-color, 70%);
$trans-time: 300ms;
$width: 100%;
$backdrop: rgba(0, 0, 0, 0.5);

// CART STYLE
$cart_background: #f7f8f8;
$items_background: #f7f8f8;
$cart_title: 1.3em;

// CART STATUS
$default_color: #666766;
$toolbar_default: #cccccc;
$cart_preparing: #000000;

$cart_ready: #000000;
$cart_new: #dedede;
$cart_printed: #a4d9ef;
$cart_completed: #64c2ea;

.bg-status-title {
  font-size: 28px;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.bg-status-default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 200px;
  min-height: 60px;
  text-transform: capitalize;
}

.bg-preparing {
  background: $cart_preparing 0% 0% no-repeat padding-box;
}

.bg-ready {
  background: $cart_ready 0% 0% no-repeat padding-box;
}

@mixin default_box {
  background-color: $cart_background;
}

@mixin new_status_box {
  background-color: $cart_new;
}

@mixin preparing_status_box {
  background-color: $cart_preparing;
}

@mixin ready_status_box {
  background-color: $cart_ready;
}

@mixin completed_status_box {
  background-color: $cart_completed;
}

@mixin printed_status_box {
  background-color: $cart_printed;
}

body {
  background-color: $bg-color;
  // background-image: url('./../assets/images/bg.png');
  background-size: cover;
  color: $pm-text-color;
  transition: background-color 500ms ease, color 1000ms ease;
}

body.light {
  background-color: $cart_background;
  color: $dk-color;
}

img.logo {
  width: 350px;
  transition: all 1000ms ease;

  &.light {
    filter: grayscale(100%) invert(100%);
  }
}

header.app-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $dk-color;

  &.light {
    background-color: $toolbar_default;
    color: $dk-color;
  }
}

.filter-icons-header {
  background-color: #000000;
  height: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
  .logo {
    align-items: center;
    height: 130px;
    object-fit: contain;
  }

  @media screen and (max-width: 750px) {
    .clock {
      display: none;
      width: 1px;
    }
  }
}

.clock {
  position: absolute;
  right: 30px;

  span {
    &:first-child {
      margin-right: 15px;
    }
    color: $pm-text-color;
    font-size: 48px;
    font-weight: bold;
    transition: color 1000ms ease;

    &.light {
      color: $dk-color;
    }
  }
}

.circle {
  &::before {
    content: ' ';
    border: 2px solid $pm-text-color;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
    background: linear-gradient(
      135deg,
      $bg-color,
      $bg-color 50%,
      $muted-color 51%,
      $muted-color 51%
    );
    transition: all $trans-time ease;
  }

  &:hover:before {
    transform: scale(1.2);
    border-color: $default_color;
  }

  &:active:before {
    transform: scale(0.9);
  }
}
