.main-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 100px);

  .wrapper-status-section {
    border-bottom: 3px solid #fff;
    height: 60px;
    width: 100%;
  }

  .status-section-wrapper {
    min-height: calc(100vh - 100px);
    width: 100%;
    display: 'flex';
    flex-direction: 'column';

    .bg-status-title {
      text-align: center;
      border-bottom: 3px solid #fff;
      margin-left: 15px;
      margin-right: 15px;
      padding-bottom: 30px;
    }

    &:first-child {
      border-right: 3px solid #fff;
    }
  }

  .status-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-gap: 1em;
    justify-content: center;
  }

  .status-title {
    font-size: 28px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    text-transform: capitalize;
    color: #fff;
  }
}
