@import './App.scss';

.ready-alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;

  .backdrop {
    background-color: $backdrop;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    align-items: center;
  }

  .alert {
    transform: scale(1.5);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1.5);
    }
    50% {
      transform: scale(1.7);
    }
  }
}
