.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
